'use client';
import { useState } from 'react';
import styles from './LoginForm.module.scss';
import clsx from 'clsx';
import { Typography } from '@/components/typography/Typography';
import FormButton from '@/components/core/FormButton/FormButton';
import { emailRegex } from '@/utils/validators';
import { useRouter } from 'next/navigation';
import EyeIcon from '@/assets/icons/eye';
import ClosedEyeIcon from '@/assets/icons/closedeye';
import Link from 'next/link';
import { signIn } from 'next-auth/react';
import AlertMessage from '@/components/AlertMessage/AlertMessage';
import ArrowIcon from '@/assets/icons/arrow';
import { useTranslations } from 'next-intl';
import { SubmitHandler, useForm } from 'react-hook-form';

type LoginFormType = {
    email: string;
    password: string;
};

const LoginForm = () => {
    const [type, setType] = useState('password');
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<LoginFormType>();
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const router = useRouter();
    const t = useTranslations('login');
    const password = watch('password');
    const username = watch('email');
    const disabled = !password || !username || !emailRegex.test(username);

    const onSubmit: SubmitHandler<LoginFormType> = async data => {
        setInvalidCredentials(false);
        if (password.length < 8 || !emailRegex.test(data.email)) {
            setInvalidCredentials(true);
            return;
        }

        try {
            const response = await signIn('credentials', {
                email: data.email,
                password: data.password,
                redirect: false,
            });
            if (!response?.ok) {
                throw response;
            }

            sessionStorage.setItem('email', data.email ?? '');
            router.push(`/profile/true`);
            router.refresh();
        } catch (err) {
            console.error(err);
            setInvalidCredentials(true);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.intro}>
                <div className={styles.row}>
                    <Link href={'/'} aria-labelledby="back button" title="back">
                        <ArrowIcon />
                    </Link>
                    <Typography.H1 classname={styles.title}>{t('title')}</Typography.H1>
                </div>
                <Typography.P classname={styles.intro}>{t('intro')}</Typography.P>
            </div>
            {invalidCredentials && (
                <div style={{ marginTop: '32px' }}>
                    <AlertMessage
                        content={t('warning.text')}
                        linkText={t('warning.linkText')}
                        linkHref={'/contact'}
                        type={'warning'}
                    />
                </div>
            )}

            <form id="formLogin" onSubmit={handleSubmit(onSubmit)} className={styles.root}>
                <fieldset className={styles.form}>
                    <div className={clsx(styles.input, invalidCredentials && styles.invalid)}>
                        <Typography.SmallStrong classname={styles.label}>
                            <label htmlFor="email">{t('usernameLabel')}</label>
                            <span className={styles.required}>{'*'}</span>
                        </Typography.SmallStrong>
                        <input
                            {...register('email')}
                            placeholder="Your email address"
                            id="email"
                            type="email"
                            name="email"
                            autoComplete="email"
                            className={styles.field}
                        />
                    </div>
                    <div className={clsx(styles.input, invalidCredentials && styles.invalid)}>
                        <Typography.SmallStrong classname={styles.label}>
                            <label htmlFor="password">{t('passwordLabel')}</label>
                            <span className={styles.required}>{'*'}</span>
                        </Typography.SmallStrong>

                        {type === 'password' && (
                            <span
                                onClick={() => setType('text')}
                                style={{
                                    alignSelf: 'flex-end',
                                    paddingRight: '40px',
                                    position: 'absolute',
                                    paddingTop: '38px',
                                }}
                            >
                                <EyeIcon />
                            </span>
                        )}
                        {type === 'text' && (
                            <span
                                onClick={() => setType('password')}
                                style={{
                                    alignSelf: 'flex-end',
                                    paddingRight: '40px',
                                    position: 'absolute',
                                    paddingTop: '38px',
                                }}
                            >
                                <ClosedEyeIcon />
                            </span>
                        )}

                        <input
                            {...register('password')}
                            type={type}
                            name="password"
                            placeholder="Your password"
                            id="password"
                            autoComplete="current-password"
                            className={styles.field}
                        />
                    </div>
                </fieldset>

                <div className={styles.recoveryWrapper}>
                    <Link href={'/recovery'} className={styles.link}>
                        <Typography.SmallLink>{t('recoveryButton')}</Typography.SmallLink>
                    </Link>
                </div>
            </form>
            <div onClick={() => onSubmit({ email: username, password: password })}>
                <FormButton text={t('submit')} disabled={disabled} />
            </div>
        </div>
    );
};
export default LoginForm;
