const ErrorAlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_1077_3452)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM1.395 10C1.395 5.2475 5.2475 1.395 10 1.395C14.7525 1.395 18.605 5.2475 18.605 10C18.605 14.7525 14.7525 18.605 10 18.605C5.2475 18.605 1.395 14.7525 1.395 10Z"
                    fill="#AF2422"
                />
                <path
                    d="M13.2708 13.8738C13.1095 13.8738 12.9408 13.8063 12.832 13.6963L9.99953 10.8638L7.16703 13.6963C7.05828 13.805 6.88953 13.8738 6.72828 13.8738C6.56703 13.8738 6.39828 13.8063 6.28953 13.6963C6.17703 13.5838 6.11328 13.4238 6.11328 13.2575C6.11328 13.0913 6.17828 12.9313 6.29078 12.8188L9.12203 10.01L6.29078 7.17875C6.17828 7.06625 6.11328 6.90625 6.11328 6.74C6.11328 6.57375 6.17828 6.41375 6.29078 6.30125C6.40328 6.18875 6.56328 6.125 6.72953 6.125C6.89578 6.125 7.05578 6.19 7.16828 6.30125L10.0008 9.13375L12.8333 6.30125C12.9458 6.18875 13.1058 6.125 13.272 6.125C13.4383 6.125 13.5983 6.19 13.7108 6.30125C13.9445 6.535 13.9445 6.945 13.7108 7.17875L10.8795 10.01L13.7108 12.8188C13.9445 13.0525 13.9445 13.4638 13.7108 13.6963C13.602 13.805 13.4333 13.8738 13.272 13.8738H13.2708Z"
                    fill="#AF2422"
                />
            </g>
            <defs>
                <clipPath id="clip0_1077_3452">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default ErrorAlertIcon;
