const SuccessAlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_1077_3441)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM1.395 10C1.395 5.2475 5.2475 1.395 10 1.395C14.7525 1.395 18.605 5.2475 18.605 10C18.605 14.7525 14.7525 18.605 10 18.605C5.2475 18.605 1.395 14.7525 1.395 10Z"
                    fill="#3C763D"
                />
                <path
                    d="M8.75127 13.6742C8.56877 13.6742 8.39752 13.603 8.27002 13.4717L5.31877 10.4717C5.19252 10.343 5.12377 10.173 5.12502 9.99297C5.12627 9.81297 5.19877 9.64422 5.32752 9.51672C5.45377 9.39172 5.62127 9.32297 5.80002 9.32297C5.97877 9.32297 6.15377 9.39422 6.28252 9.52547L8.73252 12.0167L13.7013 6.54547C13.8288 6.40547 14.0113 6.32422 14.2013 6.32422C14.3688 6.32422 14.53 6.38672 14.6538 6.49922C14.9288 6.74922 14.95 7.17672 14.7 7.45297L9.25127 13.453C9.12877 13.588 8.95377 13.668 8.77127 13.6742C8.76502 13.6742 8.75877 13.6742 8.75252 13.6742H8.75127Z"
                    fill="#3C763D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1077_3441">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default SuccessAlertIcon;
