'use client';
import ErrorAlertIcon from '@/assets/icons/erroralert';
import InfoAlertIcon from '@/assets/icons/infoalert';
import SuccessAlertIcon from '@/assets/icons/successalert';
import WarningAlertIcon from '@/assets/icons/warningalert';
import { Typography } from '@/components/typography/Typography';
import clsx from 'clsx';
import Link from 'next/link';
import styles from './AlertMessage.module.scss';

type AlertMessageProps = {
    content: any;
    linkText?: string;
    linkHref?: string;
    type: 'success' | 'warning' | 'error' | 'info' | 'invalid';
};

const AlertMessage: React.FC<AlertMessageProps> = ({ content, linkText, linkHref, type }) => {
    return (
        <div
            className={clsx(
                styles.container,
                type === 'warning' && styles.warning,
                type === 'success' && styles.success,
                (type === 'error' || type === 'invalid') && styles.error,
                type === 'info' && styles.info,
            )}
        >
            {type === 'warning' && <WarningAlertIcon />}
            {type === 'success' && <SuccessAlertIcon />}
            {(type === 'error' || type === 'invalid') && <ErrorAlertIcon />}
            {type === 'info' && <InfoAlertIcon />}
            <div className={styles.text}>
                <Typography.Small>{content}</Typography.Small>
                {linkHref && (
                    <Link href={linkHref} style={{ color: 'inherit' }}>
                        <Typography.SmallLink>{linkText}</Typography.SmallLink>
                    </Link>
                )}
            </div>
        </div>
    );
};
export default AlertMessage;
