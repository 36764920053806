const ArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M18.8571 10.7152H7.42857L11.1886 6.95519C11.2957 6.84895 11.3807 6.72255 11.4387 6.58328C11.4968 6.44401 11.5266 6.29463 11.5266 6.14376C11.5266 5.99289 11.4968 5.84351 11.4387 5.70425C11.3807 5.56498 11.2957 5.43858 11.1886 5.33233C10.9744 5.11948 10.6848 5 10.3829 5C10.0809 5 9.79127 5.11948 9.57714 5.33233L4.67429 10.2466C4.24504 10.6733 4.00255 11.2528 4 11.858C4.00556 12.4593 4.24782 13.0342 4.67429 13.458L9.57714 18.3723C9.6837 18.4781 9.81006 18.5619 9.94899 18.6189C10.0879 18.6759 10.2367 18.7049 10.3869 18.7044C10.5371 18.7039 10.6857 18.6738 10.8242 18.6158C10.9627 18.5579 11.0885 18.4732 11.1943 18.3666C11.3001 18.2601 11.3839 18.1337 11.4409 17.9948C11.4978 17.8558 11.5269 17.707 11.5264 17.5569C11.5258 17.4067 11.4957 17.2581 11.4378 17.1196C11.3798 16.981 11.2951 16.8553 11.1886 16.7495L7.42857 13.0009H18.8571C19.1602 13.0009 19.4509 12.8805 19.6653 12.6662C19.8796 12.4518 20 12.1612 20 11.858C20 11.5549 19.8796 11.2643 19.6653 11.0499C19.4509 10.8356 19.1602 10.7152 18.8571 10.7152Z"
                fill="#758974"
            />
        </svg>
    );
};
export default ArrowIcon;
