export const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegex =
    /^([a-z]+:\/\/)?((www\.)?[-a-zA-Z0-9@:%_\+.~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*))|(?:\d{1,3}\.){3}\d{1,3}$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const dateOfBirthRegex = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
export const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const nonWhitespaceRegex = /[^\s]/;
export const instagramRegex = /^\b(?:https?:\/\/)?(?:www\.)?instagram\.com\b/;
export const youtubeRegex = /^\b(?:https?:\/\/)?(?:www\.)?youtube\.com\b/;
export const tiktokRegex = /^\b(?:https?:\/\/)?(?:www\.)?tiktok\.com\b/;
export const facebookRegex = /^\b(?:https?:\/\/)?(?:www\.)?facebook\.com\b/;
