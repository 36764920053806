const InfoAlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_3607_17197)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM1.395 10C1.395 5.2475 5.2475 1.395 10 1.395C14.7525 1.395 18.605 5.2475 18.605 10C18.605 14.7525 14.7525 18.605 10 18.605C5.2475 18.605 1.395 14.7525 1.395 10Z"
                    fill="#2E6480"
                />
                <path
                    d="M10.6977 8.72094C10.6977 8.33594 10.3852 8.02344 10.0002 8.02344C9.61523 8.02344 9.30273 8.33594 9.30273 8.72094V14.3022C9.30273 14.6872 9.61523 14.9997 10.0002 14.9997C10.3852 14.9997 10.6977 14.6872 10.6977 14.3022V8.72094Z"
                    fill="#2E6480"
                />
                <path
                    d="M10.9303 5.93C10.9303 6.44375 10.5141 6.86 10.0003 6.86C9.48656 6.86 9.07031 6.44375 9.07031 5.93C9.07031 5.41625 9.48656 5 10.0003 5C10.5141 5 10.9303 5.41625 10.9303 5.93Z"
                    fill="#2E6480"
                />
            </g>
            <defs>
                <clipPath id="clip0_3607_17197">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default InfoAlertIcon;
