import { Typography } from '@/components/typography/Typography';
import { ButtonProps } from '@/types/buttons';
import clsx from 'clsx';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import styles from './Form.module.scss';

const FormButton: React.FC<ButtonProps> = ({
    text,
    disabled,
    top = '32px',
    loader = false,
    onClick,
    theme = 'original',
    size = 'full',
}) => {
    return (
        <div onClick={onClick} className={clsx(styles.container)} style={{ paddingTop: top }}>
            <div className={clsx(styles.button, styles[theme], styles[size], (disabled || loader) && styles.disabled)}>
                <ButtonLoader showLoader={loader}>
                    <Typography.Button>{text}</Typography.Button>
                </ButtonLoader>
            </div>
        </div>
    );
};
export default FormButton;
