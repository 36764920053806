import React from 'react';
import styles from './Loader.module.scss';

type LoaderType = {
    height?: string;
    width?: string;
    color?: string;
};

const Loader: React.FC<LoaderType> = ({ height = '23px', width = '23px', color = '#455d54' }) => {
    return (
        <div className={styles.loaderContainer} style={{ width: width, height: height }}>
            <div style={{ borderColor: color, borderTopColor: 'transparent' }} className={styles.loaderCircle}></div>
        </div>
    );
};

export default Loader;
