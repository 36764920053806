const WarningAlertIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
            <g clipPath="url(#clip0_3607_521)">
                <path
                    d="M21.9358 16.0678L13.416 1.31087C12.9423 0.489969 12.0935 0 11.1457 0C10.1979 0 9.34917 0.489969 8.87549 1.31087L0.35526 16.0678C-0.11842 16.8887 -0.11842 17.8687 0.35526 18.6891C0.828939 19.51 1.6777 20 2.62549 20H19.6655C20.6133 20 21.4621 19.51 21.9358 18.6891C22.4094 17.8682 22.4094 16.8883 21.9358 16.0678ZM1.76687 16.8827L10.2867 2.12577C10.4659 1.81542 10.7869 1.6298 11.1453 1.6298C11.5037 1.6298 11.8247 1.81499 12.0039 2.12534L20.5242 16.8823C20.7033 17.1926 20.7033 17.5634 20.5242 17.8738C20.345 18.1842 20.0239 18.3698 19.6655 18.3698H2.62549C2.26713 18.3698 1.94605 18.1846 1.76687 17.8742C1.58768 17.5639 1.58768 17.1931 1.76687 16.8827Z"
                    fill="#8A6D3B"
                />
                <path
                    d="M11.1469 16.137C11.7144 16.137 12.1761 15.6753 12.1761 15.1078C12.1761 14.5402 11.7144 14.0781 11.1469 14.0781C10.5793 14.0781 10.1172 14.5398 10.1172 15.1078C10.1172 15.6758 10.5789 16.137 11.1469 16.137Z"
                    fill="#8A6D3B"
                />
                <path
                    d="M11.3184 6.14453H10.9755C10.6201 6.14453 10.332 6.43261 10.332 6.78796V12.4936C10.332 12.8489 10.6201 13.137 10.9755 13.137H11.3184C11.6738 13.137 11.9618 12.8489 11.9618 12.4936V6.78796C11.9618 6.43261 11.6738 6.14453 11.3184 6.14453Z"
                    fill="#8A6D3B"
                />
            </g>
            <defs>
                <clipPath id="clip0_3607_521">
                    <rect width="22.2912" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default WarningAlertIcon;
