import React from 'react';
import Loader from '../Loader/Loader';

type ButtonLoaderProps = {
    showLoader: boolean;
    children: React.ReactNode;
};

const ButtonLoader: React.FC<ButtonLoaderProps> = ({ showLoader, children }) => {
    return <div>{showLoader ? <Loader /> : children}</div>;
};

export default ButtonLoader;
